<template>
    <div class="container">
    <div class="nav">
      <img
        src="@/assets/images/statistical/return.png"
        alt=""
        class="return_img"
        @click="returnBtn"
      />
    </div>
     <div class="boxbg">
         <div class="boxl">
            <img src="@/assets/images/index/总商家.png" alt="">
           <div>
              <span>2613</span>
              <span>入驻商户</span>
           </div>
         </div>
         <div class="centerbox">
            <img src="@/assets/images/index/small_circle1.png" alt="">
             <div class="mycenterbox">

                <el-tooltip class="item" effect="dark" content="广州市朗坤环境科技有限公司" placement="top-start">
                    <div class="myboxs"></div>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="贵州锦江生物能源科技有限公司" placement="top-start">
                    <div class="myboxs"></div>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="湖南越大能源有限公司" placement="top-start">
                    <div class="myboxs"></div>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="江苏吉升新能源科技有限公司" placement="top-start">
                    <div class="myboxs"></div>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="四川蓝邦新能源科技有限公司" placement="top-start">
                    <div class="myboxs"></div>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="乐山纯一新能源科技有限公司" placement="top-start">
                    <div class="myboxs"></div>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="宿州市书昕油脂化工有限公司" placement="top-start">
                    <div class="myboxs"></div>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="云南韵旺油脂有限公司" placement="top-start">
                    <div class="myboxs"></div>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="石宝山扶贫开发投资有限公司" placement="top-start">
                    <div class="myboxs"></div>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="湛江中皓能源有限公司" placement="top-start">
                    <div class="myboxs"></div>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="长沙胡兴商贸有限公司" placement="top-start">
                    <div class="myboxs"></div>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="保定市万盈胖胖商贸有限公司" placement="top-start">
                    <div class="myboxs"></div>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="江苏吉升新能源科技有限公司" placement="top-start">
                    <div class="myboxs"></div>
                </el-tooltip>
            </div> 
  
             

         </div>
         <div class="boxl">
            <img src="@/assets/images/index/qyl.png" alt="">
           <div>
              <span>13</span>
              <span>处置企业</span>
           </div>
         </div>
     </div>

    </div>
  </template>
  
  <script>
 
  export default {
    data() {
      return {
        
      };
    },
    mounted() {
    
    },
    methods: {
    
        returnBtn() {
    
        this.$router.replace({ name: "Index" });
    
    }
    }
  };
  </script>
  
  <style lang="scss" scoped>
.boxbg{
    
    width: 1000px;
    justify-content: space-around;
    display: flex;
    align-items: center;
    padding-top: 180px;
    .centerbox{
        position: relative;
           width: 582px;
           height: 582px;
           img{
            position: absolute;
            width: 582px;
           height: 582px;
           }
           .mycenterbox{
            width: 582px;
           height: 582px;
           position: relative;
           border-radius: 50%;
           margin-top: 16px;
           transform: rotate(-7deg);
           .myboxs{
                width: 84px;
                height: 84px;
                position: absolute;
               cursor: pointer;
                border-radius: 50%;
                line-height: 40px;
                text-align: center;
                left: 50%;
                margin-left: -10px;
                transform-origin:17px 284px;
                margin-top: -10px;
               
            }
            .myboxs:nth-of-type(2){
                transform: rotate(27.69deg);
                
            }
            .myboxs:nth-of-type(3){
                transform: rotate(55.28deg);
               
            }
            .myboxs:nth-of-type(4){
                transform: rotate(83.07deg);
               
            }
            .myboxs:nth-of-type(5){
                transform: rotate(110.76deg);
               
            }
            .myboxs:nth-of-type(6){
                transform: rotate(138.45deg);
              
            }
            .myboxs:nth-of-type(7){
                transform: rotate(166.14deg);
                
            }
            .myboxs:nth-of-type(8){
                transform: rotate(193.83deg);
               
            }
            .myboxs:nth-of-type(9){
                transform: rotate(221.52deg);
               
            }
            .myboxs:nth-of-type(10){
                transform: rotate(249.21deg);
              
            }
            .myboxs:nth-of-type(11){
                transform: rotate(276.9deg);
           
            }
            .myboxs:nth-of-type(12){
                transform: rotate(304.59deg);
            
            }
            .myboxs:nth-of-type(13){
                transform: rotate(332.28deg);
          
            }
           }
    }
    .boxl{
        width: 130px;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        height: 220px;
        img{
            width: 130px;
            height: 220px;
            position: absolute;
            z-index: 1;
        }
        div{
            width: 130px;
            align-items: center;
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
             overflow: hidden;
            font-size: 32px;
font-family: Adobe Heiti Std;
font-weight: normal;
color: #FFFFFF;
line-height: 35px;

background: linear-gradient(0deg, #FBEBC2 0%, #FFFFFF 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
span:nth-of-type(1){
  margin-top: 26px;
  margin-bottom: 4px;
}
span:nth-of-type(2){
    font-size: 18px;
font-family: Adobe Heiti Std;
font-weight: normal;
color: #FFFBF2;
}
        }
    }
}

    
  .nav {
   position: fixed;
   top: 100px;
   left: 20px;
  .return_img {
    height: 100%;
    cursor: pointer;
  }
}
 
  .container {
    width: 100%;
    height: 100%;
  }
  </style>
  